import React from "react";
import { graphql } from "gatsby";

import Page from "../templates/Page";

const IndexPage = ({ data }) => {
  return <Page data={data} />;
};

export const pageQuery = graphql`
  query {
    page: markdownRemark(frontmatter: { path: { eq: "/" } }) {
      ...pageFragment
    }
  }
`;

export default IndexPage;
